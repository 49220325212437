import { ConfigProvider, Table, theme } from "antd"
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ApiService from "../../api";
import utils from "../../utils";
import SearchColumn from "../../components/SearchColumn";
import SortColumn from "../../components/SortColumn";
import notificationService from "../../services/notification.service";
import { usePagination, PaginationComponent } from "../../components/BeaconPagination";

export default function PaymentScheduleList({ entity, entityId, isDraft }) {

    const { token: { themeFontColor, themePrimaryColor }, } = theme.useToken();

    const navigate = useNavigate();

    // State Variables
    const [loading, setLoading] = useState(false);
    const [paymentScheduleData, setPaymentScheduleData] = useState([]);

    // Search Field
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const [customSort, setCustomSort] = useState({
        ap_assetDescription: { order: null, type: 'string' },
        ap_baseAmount: { order: null, type: 'string' },
        ap_term: { order: null, type: 'string' },
        ap_costOfFunds: { order: null, type: 'string' },
        ap_clientRate: { order: null, type: 'string' },
        ap_regularMonthlyPayment: { order: null, type: 'string' },
        ap_downPayment: { order: null, type: 'string' },
    });

    useEffect(() => {
        if (entity && entityId) {
            if (entity === 'approval') {
                setLoading(true);
                ApiService.fetchApprovalPaymentSchedulesList(entityId, isDraft).then((data,) => {
                    setPaymentScheduleData(data);
                    setLoading(false);
                });
            } else if (entity === 'drawdown') {
                setLoading(true);
                ApiService.fetchDrawdownPaymentSchedulesList(entityId).then((data) => {
                    setPaymentScheduleData(data);
                    setLoading(false);
                });
            } else notificationService.error('Error', 'Cannot fetch Payment Schedules. Please try again later')
        } else notificationService.error('Error', 'Cannot fetch Payment Schedules. Please try again later')
    }, [entity, entityId]);

    const route = (entity === 'approval') ? `/approvals/${entityId}` : `/drawdowns/${entityId}`

    const { pagedData, finalData, currentPage, setCurrentPage } = usePagination({
        tableData: paymentScheduleData,
        pageSize: 10,
        showPagination: true,
        searchedColumn: searchedColumn,
        searchText: searchText,
        filters: {},
        customSort: customSort,
    });

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorBgContainer: themePrimaryColor,
                    colorFillAlter: themePrimaryColor,
                    colorFillSecondary: themePrimaryColor,
                    colorText: themeFontColor,
                    colorIcon: themeFontColor,
                    colorTextPlaceholder: themeFontColor,
                    borderRadius: '50',
                },
            }}
        >
            <Table
                id={PaymentScheduleList.name}
                columns={generateApprovalsListTableColumns(searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput, customSort, setCustomSort)}
                dataSource={pagedData}
                loading={loading}
                scroll={{ x: true }}
                onRow={(record) => ({
                    onClick: () => {
                        navigate(route + `/paymentschedules/${record._id}`)
                    },
                    className: 'clickable-row'
                })}
                pagination={false}
                footer={() =>  <PaginationComponent 
                    finalData={finalData}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    pageSize={10}
                    tableId={PaymentScheduleList.name}/>}
            />
        </ConfigProvider>
    );

}

function generateApprovalsListTableColumns(searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput, customSort, setCustomSort) {

    return ([
        {
            title: 'Asset Description',
            dataIndex: 'ap_assetDescription',
            key: 'ap_assetDescription',
            ...SortColumn(customSort, setCustomSort, 'ap_assetDescription'),
            ...SearchColumn(searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput, 'ap_assetDescription'),
        },
        {
            title: 'Base Amount',
            key: 'ap_baseAmount',
            render: (record) => (utils.TransformDBCurrency(record.ap_baseAmount)).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
            ...SortColumn(customSort, setCustomSort, 'ap_baseAmount'),
        },
        {
            title: 'Term',
            key: 'ap_term',
            render: (record) => (record.ap_term + " months"),
            ...SortColumn(customSort, setCustomSort, 'ap_term'),
        },
        {
            title: 'Cost of Funds',
            key: 'ap_costOfFunds',
            render: (record) => (Number(record.ap_costOfFunds).toFixed(2) + "%"),
            ...SortColumn(customSort, setCustomSort, 'ap_costOfFunds'),
        },
        {
            title: 'Client Rate',
            key: 'ap_clientRate',
            render: (record) => (Number(record.ap_clientRate).toFixed(2) + "%"),
            ...SortColumn(customSort, setCustomSort, 'ap_clientRate'),
        },
        {
            title: 'Regular Monthly Payments',
            key: 'ap_regularMonthlyPayment',
            render: (record) => (utils.TransformDBCurrency(record.ap_regularMonthlyPayment)).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
            ...SortColumn(customSort, setCustomSort, 'ap_regularMonthlyPayment'),
        },
        {
            title: 'Down Payment',
            dataIndex: 'ap_downPayment',
            key: 'ap_downPayment',
            ...SortColumn(customSort, setCustomSort, 'ap_downPayment'),
        },
    ]);

}
