import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ConfigProvider, Table, theme } from "antd";
import { usePagination, PaginationComponent } from "../../components/BeaconPagination";
import ApiService from "../../api";
import cancelledConstants from "./constants";

import styles from "./index.module.css";

export default function CancelledDrawdownsList({ entity, entityId }) {

    const { token: { themeFontColor, themePrimaryColor }, } = theme.useToken();

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [uncleanData, setUncleanData] = useState();
    const [cleanData, setCleanData] = useState([]);

    // Search Field
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [customSort, setCustomSort] = useState({
        lcNumber: { order: null, type: 'string' },
        clientName: { order: null, type: 'string' },
        lenderName: { order: null, type: 'string' },
        cancelledBy: { order: null, type: 'string' },
        cancelledDate: { order: null, type: 'date' },
    });

    useEffect(() => {
        setLoading(true);
        if (entity === 'agent')
            ApiService.fetchAgentCancelledDrawdowns(entityId).then((data) => {
                setUncleanData(data);
                setLoading(false);
            });
        else if (entity === 'client')
            ApiService.fetchClientCancelledDrawdowns(entityId).then((data) => {
                setUncleanData(data);
                setLoading(false);
            });
    }, [entity, entityId]);

    useEffect(() => {
        const tempData = [];
        for (let i = 0; i < uncleanData?.length; i++) {
            tempData.push({
                id: uncleanData[i]?._id,
                lcNumber: uncleanData[i]?.crm_lcNumber,
                clientName: uncleanData[i]?.ap_clientId?.ap_businessName,
                assetDescription: uncleanData[i]?.ap_assetDescription,
                lenderName: uncleanData[i]?.ap_lenderId?.ap_abbreviation || uncleanData[i]?.ap_lenderId?.ap_name,
                agentFee: uncleanData[i]?.ap_agentFee,
                drawdownFee: uncleanData[i]?.ap_drawdownFee,
                cancelledBy: uncleanData[i]?.ap_drawdownCancelledBy,
                cancelledDate: uncleanData[i]?.ap_drawdownCancelledDate,
                status: cancelledConstants?.generateProcessStage(uncleanData[i]),
            });
        }
        setCleanData(tempData);
    }, [uncleanData])

    const { pagedData, finalData, currentPage, setCurrentPage } = usePagination({
        tableData: cleanData,
        pageSize: 10,
        showPagination: true,
        searchedColumn: searchedColumn,
        searchText: searchText,
        filters: {},
        customSort: customSort,
    });

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorBgContainer: themePrimaryColor,
                    colorFillAlter: themePrimaryColor,
                    colorFillSecondary: themePrimaryColor,
                    colorText: themeFontColor,
                    colorIcon: themeFontColor,
                    colorTextPlaceholder: themeFontColor,
                    borderRadius: '50',
                },
            }}
        >
            <Table
                id={CancelledDrawdownsList.name}
                columns={cancelledConstants.generateTableColumns(searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput, navigate, entity, customSort, setCustomSort)}
                dataSource={pagedData}
                loading={loading}
                scroll={{ x: true, y: 400 }}
                onRow={(record) => ({
                    onClick: () => {
                        navigate(`/drawdowns/${record.id}`);
                    },
                    className: styles["CancelledDrawdownRow"]
                })}
                pagination={false}
                footer={() =>  <PaginationComponent 
                    finalData={finalData}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    pageSize={10}
                    tableId={CancelledDrawdownsList.name}/>}
            />
        </ConfigProvider>
    );
}