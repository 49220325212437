import { useEffect, useState } from "react";
import { Alert, ConfigProvider, Form, Input, Table, Tooltip, theme } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import ApiService from "../../api";
import NotificationService from "../../services/notification.service";

import { usePagination, PaginationComponent } from "../../components/BeaconPagination";

import CreateNewAsset from "../CreateNewAsset";
import CreateMultipleAssets from "../CreateMultipleAssets";

import EditIcon from "../../assets/images/icons/EditIcon";
import DeleteIcon from "../../assets/images/icons/DeleteIcon";

import assetConstants from "./constants";
import utils from "../../utils";

import "../../assets/styles/fonts.css";
import styles from "./index.module.css";

export default function AssetDrafts({ showAssetError, setShowAssetError, id, clientId, assets, setAssets, refreshAssets, setRefreshAssets, isAppOpen, setUnsaved }) {
    const { token: { ap_userPrimaryColor, themePrimaryColor, themeFontColor, themeDisabledContainer }, } = theme.useToken();

    const [requestedAmount, setRequestedAmount] = useState(0);
    const [customSort, setCustomSort] = useState({
        ap_type: { order: null, type: 'string' },
        ap_make: { order: null, type: 'string' },
        ap_purpose: { order: null, type: 'string' },
    });

    const [editHovered, setEditHovered] = useState(false);
    const EditAssetIcon = (allowEdit) => (
        <div
            onMouseEnter={() => setEditHovered(true)}
            onMouseLeave={() => setEditHovered(false)}
            className={`${styles["PopoverContainer_List"]} ${allowEdit ? styles["Edit"] : styles["DisabledAction"]}`}
        >
            <EditIcon hoverControlled={editHovered} customStyle={{ width: '20px', }} editColor={!allowEdit ? "darkgray" : ''} />
            <div>Edit</div>
        </div>
    )

    const [deleteHovered, setDeleteHovered] = useState(false);
    const DeleteAssetIcon = (index, asset, allowDelete) => {
        return (
            <div
                onMouseEnter={() => setDeleteHovered(true)}
                onMouseLeave={() => setDeleteHovered(false)}
                className={`${styles["PopoverContainer_List"]} ${allowDelete ? styles["Delete"] : styles["DisabledAction"]}`}
                onClick={() => {
                    const assetStatus = asset?.ap_assetStatus;
                    const { contactDetails } = utils.IndividualAssetManagement(assetStatus);
                    const { label, contact } = contactDetails;
                    if (allowDelete)
                        deleteAsset(index, asset);
                    else NotificationService.warning(<>This Asset is already {assetStatus}.<br />Please contact the <a href={`mailto:${contact}`}>{label} Team</a> to make changes</>, null, false)
                }}
            >
                <DeleteIcon hoverControlled={deleteHovered} customStyle={{ width: '20px' }} editColor={!allowDelete ? "darkgray" : ""} />
                <div>Delete</div>
            </div>
        )
    }

    const deleteAsset = (index, asset) => {

        const newData = {
            ...asset,
            ap_deleted: true,
        };

        setAssets(prevAssets => {
            const newAssets = [...prevAssets];
            newAssets[index] = newData;
            return newAssets;
        });
    }

    useEffect(() => {
        let totalAssetPrice = 0;
        assets.forEach(asset => {
            totalAssetPrice += Number(asset?.ap_price);
            if (asset?.ap_vendorId && typeof asset?.ap_vendorId !== 'object')
                ApiService.fetchIndividualVendor(asset?.ap_vendorId).then((response) => {
                    asset.ap_vendorId = { ...response };
                });
        });
        setRequestedAmount(totalAssetPrice);
        setShowAssetError(false)
    }, [assets]);

    const { pagedData, finalData, currentPage, setCurrentPage } = usePagination({
        tableData: assets.map((asset, index) => ({ ...asset, key: index })),
        pageSize: 10,
        showPagination: false,
        searchedColumn: null,
        searchText: null,
        filters: {},
        customSort: customSort,
    });
    return (
        <div id="AssetContainer" className={styles["Container"]}>

            <div className={styles["HeaderContent"]}>
                <div className={styles["Header"]}>Asset Details</div>
                <div className={styles["HeaderButtons"]}>
                    <CreateNewAsset
                        newApp={true}
                        disabled={!isAppOpen}
                        assets={assets}
                        setAssets={setAssets}
                        setUnsaved={setUnsaved}
                    />
                    <CreateMultipleAssets
                        newApp={true}
                        disabled={!isAppOpen}
                        assets={assets}
                        setAssets={setAssets}
                        refreshAssets={refreshAssets}
                        setRefreshAssets={setRefreshAssets}
                        secondaryButton={true}
                        setUnsaved={setUnsaved}
                    />
                </div>
            </div>

            <Alert
                message={<b>Error</b>}
                description="Please specify the assets before sending an application."
                type="error"
                style={{ display: showAssetError ? 'flex' : 'none', textAlign: 'left' }}
                showIcon
            />

            <div>
                <ConfigProvider
                    theme={{
                        token: {
                            colorBgContainer: themePrimaryColor,
                            colorFillAlter: themePrimaryColor,
                            colorFillSecondary: themePrimaryColor,
                            colorText: themeFontColor,
                            colorIcon: themeFontColor,
                            colorTextPlaceholder: themeFontColor,
                        },
                    }}
                >
                    <Table
                        id={AssetDrafts.name}
                        columns={assetConstants.generateAssetTableColumns(assets, setAssets, customSort, setCustomSort, EditAssetIcon, DeleteAssetIcon, setUnsaved)}
                        dataSource={pagedData.filter(({ ap_deleted }) => !ap_deleted)}
                        scroll={{ x: 320, y: 400 }}
                        pagination={false}
                        footer={() => <PaginationComponent 
                            finalData={finalData}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            pageSize={10}
                            showPagination={false}
                            tableId={AssetDrafts.name}/>}
                    />

                </ConfigProvider>
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: "20px" }}>
                <Form layout="horizontal">
                    <ConfigProvider
                        theme={{
                            token: {
                                activeBorderColor: ap_userPrimaryColor,
                                hoverBorderColor: ap_userPrimaryColor,
                                colorBgContainer: themePrimaryColor,
                                colorBgContainerDisabled: themeDisabledContainer,
                                colorText: themeFontColor,
                                colorTextPlaceholder: themeFontColor,
                                colorTextDisabled: themeFontColor,
                                colorIcon: themeFontColor,
                            }
                        }}
                    >
                        <Form.Item label="Total Requested Amount">
                            <Input
                                value={(requestedAmount).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                                suffix={(
                                    <Tooltip title="Requested Amount will be updated automatically after adding assets.">
                                        <InfoCircleOutlined className={styles["icon"]} />
                                    </Tooltip>
                                )}
                                disabled
                            />
                            <div className={styles["ReqAmtInfo"]}>Requested amount will be shown here after adding assets</div>
                        </Form.Item>
                    </ConfigProvider>
                </Form>
            </div>
        </div >
    );
}