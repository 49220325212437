import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ConfigProvider, Table, theme } from "antd";

import AuthService from "../../services/auth.service";
import ApiService from "../../api";

import { usePagination, PaginationComponent } from "../../components/BeaconPagination";
import generateRequirementsTableColumns from "./constants";

import styles from "./index.module.css";

export default function RequirementsList() {

    const { token: { themeFontColor, themePrimaryColor, userHighlightColor }, } = theme.useToken();

    const navigate = useNavigate();

    const [requirementsData, setRequirementsData] = useState([]);

    const [agentId, setAgentId] = useState(null);
    const [refresh, setRefresh] = useState(0);
    const [loader, setLoader] = useState({ loading: false, tip: '' });

    // Fetch agent Id
    useEffect(() => {
        const isLoggedIn = AuthService.isLoggedIn();
        const authGuard = AuthService.authGuard();
        if (!authGuard)
            return;

        if (!isLoggedIn) {
            setRefresh(prevRefresh => prevRefresh + 1);
        } else {
            if (AuthService.getCurrentUser().agent_id)
                setAgentId(AuthService.getCurrentUser().agent_id);
            else setRefresh(prevRefresh => prevRefresh + 1);
        }
    }, [refresh]);

    // Fetch Requirements
    useEffect(() => {
        if (!agentId) return;
        setLoader({ loading: true, tip: 'Loading...' })
        ApiService.fetchRequirementsList(agentId).then((data) => {
            setRequirementsData(data.map(item => ({ ...item, key: item._id })));
            setLoader({ loading: false, tip: '' });
        });
    }, [agentId, refresh]);

    const { pagedData, finalData, currentPage, setCurrentPage } = usePagination({
        tableData: requirementsData,
        pageSize: 10,
        showPagination: true,
        searchedColumn: null,
        searchText: null,
        filters: {},
        customSort: {},
    });

    return (
        <div className={styles["Requirements_Table"]}>
            <ConfigProvider
                theme={{
                    token: {
                        colorBgContainer: themePrimaryColor,
                        colorFillAlter: themePrimaryColor,
                        colorFillSecondary: themePrimaryColor,
                        colorText: themeFontColor,
                        colorIcon: themeFontColor,
                        colorTextPlaceholder: themeFontColor,
                        borderRadius: '50%',
                    },
                }}
            >
                <Table
                    id={RequirementsList.name}
                    columns={generateRequirementsTableColumns(navigate)}
                    dataSource={pagedData}
                    pagination={false}
                    footer={() => <PaginationComponent 
                        finalData={finalData}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        pageSize={10}
                        tableId={RequirementsList.name}/>}
                    loading={loader.loading}
                />
            </ConfigProvider>
        </div>
    );
}