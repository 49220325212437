import React from "react";
import { Button, ConfigProvider, Pagination, theme } from "antd";
import * as XLSX from "xlsx";
import PreviousIcon from "../../assets/images/icons/PreviousIcon";
import NextIcon from "../../assets/images/icons/NextIcon";
import NotificationService from "../../services/notification.service";

export default function PaginationComponent({
    tableId,
    finalData,
    currentPage,
    setCurrentPage,
    pageSize = 10,
    showPagination = true,
    showExport = true,
}) {
    const { token: { themeFontColor, themePrimaryColor } } = theme.useToken();
    const totalItems = finalData.length;
    const maxPage = Math.ceil(totalItems / pageSize);

    const UserTheme = {
        token: {
            colorBgContainer: themePrimaryColor,
            colorText: themeFontColor,
            borderRadius: "50%",
        },
    };

    // Handle page changes
    const handlePageChange = page => setCurrentPage(page);

    // Generate Excel file
    const generateExcelBook = () => {
        if (totalItems === 0 || !tableId) return;

        try {
            const tableElement = document.getElementById(tableId);
            const workbook = XLSX.utils.table_to_book(tableElement);
            const ws = workbook.Sheets["Sheet1"];
            XLSX.utils.sheet_add_aoa(ws, [[], ["Created " + new Date().toISOString()]], { origin: -1 });
            XLSX.writeFile(workbook, `${tableId}.xlsx`);
            NotificationService.success("Download Complete");
        } catch (error) {
            NotificationService.error("Failed to export Excel sheet.");
        }
    };

    // Custom item renderer for Previous/Next buttons
    const itemRender = (_, type, originalElement) => {
        if (type === "prev") return <PreviousIcon enabled={currentPage > 1} />;
        if (type === "next") return <NextIcon enabled={currentPage < maxPage} />;
        return originalElement;
    };

    return (
        <div style={{ display: "flex", flexDirection: "row" }}>
                <ConfigProvider theme={UserTheme}>
                    <div style={{ flexGrow: 5 }}>
                        {showPagination && (
                            <Pagination
                                total={totalItems}
                                pageSize={pageSize}
                                current={currentPage}
                                onChange={handlePageChange}
                                showSizeChanger={false}
                                itemRender={itemRender}
                            />
                        )}
                    </div>

                    {showExport && (
                        <div >
                            <Button
                                type="primary"
                                style={{ borderRadius: 0 }}
                                onClick={() => generateExcelBook()}
                            >Export Excel</Button>
                        </div>
                    )}
                </ConfigProvider>
            </div>
    );
}
