import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Table, Spin, theme, ConfigProvider } from "antd";

import ApiService from "../../api";

import AssetListConstants from './constants';
import { usePagination, PaginationComponent } from '../../components/BeaconPagination';
import NotificationService from "../../services/notification.service";

import utils from "../../utils";

import styles from './index.module.css';

export default function AssetList({ data }) {

    const { token: { themeFontColor, themePrimaryColor, userHighlightColor }, } = theme.useToken();

    // State Variables
    const [loading, setLoading] = useState(false);
    const [assets, setAssets] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedAssets, setSelectedAssets] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [ppsId, setPpsId] = useState("");
    const [ppsData, setPpsData] = useState({});
    const [assetsLoading, setAssetsLoading] = useState(false);
    const [filters, setFilters] = useState({});
    const [customSort, setCustomSort] = useState({
        ap_type: { order: null, type: 'string' },
        ap_make: { order: null, type: 'string' },
        ap_purpose: { order: null, type: 'string' },
    });

    const navigate = useNavigate();
    const { id } = useParams();

    // fetch asset list
    useEffect(() => {
        setAssetsLoading(true);
        ApiService.fetchApprovalAssetList(id).then(data => {
            setAssetsLoading(false);
            if (!data.data || data.data.length === 0) return;
            setAssets(data.data.map(asset => asset.ap_assetId));
        });
    }, [id]);

    // fetch approved pps data
    useEffect(() => {
        ApiService.fetchApprovalPaymentSchedulesList(id, false).then(data => {
            if (!data || data.length === 0) return;
            setPpsData(data[0]);
            setPpsId(data[0]._id);
        });
    }, [id]);

    const onSelectChange = (newSelectedRowKeys, selectdRows) => {
        let sumOfPrices = 0;
        setSelectedAssets([]);
        selectdRows.forEach(async (obj) => {
            const { _id, ap_price } = obj;
            sumOfPrices += Number(ap_price);

            // TODO: to work on concatenating the Asset Description

            setSelectedAssets(prevAsset => ([
                ...prevAsset,
                _id
            ]))
        });
        // setTotalPrice(utils.parseDBCurrency(sumOfPrices).toLocaleString('en-US', { style: 'currency', currency: 'USD' }));
        setTotalPrice(utils.parseDBCurrency(sumOfPrices));
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    const createPPS = () => {
        // Get approved PPS Data
        if (ppsId) {
            setLoading(true);
            // construct data for PPS-MS call
            const newPpsData = AssetListConstants.generatePpsData(ppsData, totalPrice);
            // PPS-MS call
            ApiService.fetchBalancedPPS(newPpsData).then((response) => {
                if (response?.success) {
                    const ppsAssetIds = [];
                    let ap_ppsDraftId;
                    // constuct draft data using PPS new balanced data
                    const ppsDraftData = AssetListConstants.generatePpsDraftData(ppsData, response.success.completeValueSet, selectedAssets);
                    // POST service call to create new PPS Draft Entity
                    ApiService.createDraftPaymentSchedule(ppsDraftData).then((response) => {
                        if (response?.success) {
                            ap_ppsDraftId = response.success.paymentScheduleDraftId;
                            const obj = {
                                ap_requestNewPps: true,
                                ap_lastModifiedBy: "beacon",
                                ap_assetDeclined: false
                            }
                            // Update the amendment flag in Approval Entity
                            ApiService.updateApproval(id, obj).then((response) => {
                                if (!response.success)
                                    NotificationService.error('Error', 'Something went wrong, try again later');
                            });

                            // Create an array of promises for creating draft pps assets
                            const assetCreationPromises = selectedAssets.map(assetId => {
                                const draftPpsAssetData = AssetListConstants.generateDraftPpsAssetData(ap_ppsDraftId, assetId, assets[0].ap_applicationId);
                                return ApiService.createDraftPaymentScheduleAsset(draftPpsAssetData).then(response => {
                                    if (response.success) {
                                        ppsAssetIds.push(response.success.paymentScheduleAssetId);
                                    } else {
                                        throw new Error('Error in requesting draft PPS asset');
                                    }
                                });
                            });

                            // Wait for all asset creation promises to resolve
                            Promise.all(assetCreationPromises).then(() => {
                                const newPpsRequestData = AssetListConstants.generateNewPpsRequestData(ap_ppsDraftId, ppsAssetIds, id);
                                // POST call to create new PPS Request
                                ApiService.createNewPPSRequest(newPpsRequestData).then(response => {
                                    if (response.success) {
                                        NotificationService.success('Success', 'New Schedule Requested');
                                        setLoading(false);
                                        navigate(`/approvals/${id}/paymentschedules/${ap_ppsDraftId}`);
                                    } else {
                                        setLoading(false);
                                        NotificationService.error('Error', 'There was an error in requesting new payment schedule, please try again later');
                                    }
                                });
                            }).catch(error => {
                                setLoading(false);
                                NotificationService.error('Error', error.message);
                            });
                        } else {
                            setLoading(false);
                            NotificationService.error('Error', "There was an error in requesting new schedule, please try again later");
                        }
                    });
                } else {
                    setLoading(false);
                }
            });
        }
    };


    const { pagedData, finalData, currentPage, setCurrentPage } = usePagination({
        tableData: assets.map((asset, index) => ({ ...asset, key: index })),
        pageSize: 10,
        showPagination: false,
        searchedColumn: null,
        searchText: null,
        filters: {},
        customSort: customSort,
    });

    return (
        <>
            <Spin spinning={loading} tip="Creating Payment Schedule..." size="large">
                <div>
                    <div
                        style={{
                            justifyContent: 'right',
                            display: 'flex',
                        }}
                    >
                        <Button type="primary" onClick={createPPS} disabled={!hasSelected} className={styles["RequestPPSbutton"]}>
                            Request New Payment Schedule
                        </Button>
                    </div>

                    <ConfigProvider
                        theme={{
                            token: {
                                colorBgContainer: themePrimaryColor,
                                colorFillAlter: themePrimaryColor,
                                colorFillSecondary: themePrimaryColor,
                                colorText: themeFontColor,
                                colorIcon: themeFontColor,
                                colorTextPlaceholder: themeFontColor,
                                borderRadius: '50',
                            },
                        }}
                    >
                        <Table
                            id="ApprovalAssetList"
                            rowSelection={data?.ap_status !== 'Cancelled' && assets.length > 1 ? rowSelection : null}
                            columns={AssetListConstants.generateAssetTableColumns(assets, setFilters, customSort, setCustomSort)}
                            dataSource={pagedData}
                            scroll={{ x: true }}
                            pagination={false}
                            loading={assetsLoading}
                            footer={() => <PaginationComponent 
                                finalData={finalData}
                                currentPage={currentPage}
                                showPagination={false}
                                setCurrentPage={setCurrentPage}
                                pageSize={10}
                                tableId={'ApprovalAssetList'}/>}
                        />

                    </ConfigProvider>

                </div>
            </Spin>
        </>
    );
}