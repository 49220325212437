import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, theme, ConfigProvider } from 'antd';
import dayjs from 'dayjs';

import usePagination from '../../components/BeaconPagination/usePagination';
import PaginationComponent from '../../components/BeaconPagination/PaginationComponent';
import ApiService from '../../api';
import SearchColumn from '../../components/SearchColumn';
import SortColumn from '../../components/SortColumn';

export default function ApplicationList({ agentId, status, tab }) {

    const { token: { themePrimaryColor, themeFontColor }, } = theme.useToken();

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [applicationListData, setApplicationListData] = useState([]);

    // Search Field
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const [customSort, setCustomSort] = useState({
        createdAt: { order: null, type: 'string' },
        ap_client: { order: null, type: 'string' },
        ap_type: { order: null, type: 'string' },
    });

    // Update application list data on agentId change
    useEffect(() => {
        setLoading(true);
        const filters = { ap_stateCode: status }
        ApiService.fetchAgentApplications(filters).then((data) => {
            if (!data) return;
            setApplicationListData(() => {
                data.map((item) => {
                    item.ap_client = item?.ap_clientId?.ap_businessName;
                    return item;
                });
                return data;
            });
            setLoading(false);
        })
    }, [tab, agentId]);

    const { pagedData, finalData, currentPage, setCurrentPage } = usePagination({
        tableData: applicationListData,
        pageSize: 10,
        showPagination: true,
        searchedColumn: searchedColumn,
        searchText: searchText,
        filters: {},
        customSort: customSort,
    });
    
    return (
        <div>
            <ConfigProvider
                theme={{
                    token: {
                        colorBgContainer: themePrimaryColor,
                        colorFillAlter: themePrimaryColor,
                        colorFillSecondary: themePrimaryColor,
                        colorText: themeFontColor,
                        colorIcon: themeFontColor,
                        colorTextPlaceholder: themeFontColor,
                        borderRadius: '50',
                    },
                }}
            >
                <Table
                    id={ApplicationList.name}
                    columns={generateTableColumns(searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput, customSort, setCustomSort)}
                    dataSource={pagedData}
                    onRow={(record) => ({
                        onClick: () => {
                            navigate(`/applications/${record._id}`);
                        },
                        className: 'clickable-row'
                    })}
                    scroll={{ x: true, y: 420 }}
                    pagination={false}
                    footer={() => 
                        <PaginationComponent 
                            finalData={finalData}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            pageSize={10}
                            tableId={ApplicationList.name}/>
                    }
                    loading={loading}
                />
            </ConfigProvider>
        </div>
    );
}

// TODO: To be migrated to API Manager
function generateTableColumns(searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput, customSort, setCustomSort) {

    return [
        {
            title: 'Submitted On',
            key: 'createdAt',
            ellipsis: { showTitle: false },
            ...SortColumn(customSort, setCustomSort, 'createdAt'),
            render: (record) => (dayjs(record?.createdAt).format('DD MMM YYYY'))
        },
        {
            title: 'Client',
            key: 'ap_clientId',
            dataIndex: 'ap_client',
            ellipsis: { showTitle: false },
            ...SortColumn(customSort, setCustomSort, 'ap_client'),
            ...SearchColumn(searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput, 'ap_client')
        },
        {
            title: 'Asset Description',
            dataIndex: 'ap_assetDescription',
            key: 'ap_assetDescription',
            ellipsis: { showTitle: false },
        },
        {
            title: 'Requested Amount',
            key: 'ap_requestedAmount',
            ellipsis: { showTitle: false },
            render: (record) => (record?.ap_requestedAmount / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
        }
    ];
}