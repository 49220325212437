import { useEffect, useState, useRef } from "react";
import utils from "../../utils";

function usePagination({
    tableData,
    pageSize = 10,
    showPagination = true,
    searchedColumn = null,
    searchText = null,
    filters = {},
    customSort = {},
}) {
    // State variables
    const [finalData, setFinalData] = useState([]); // Filtered and sorted data
    const [currentPage, setCurrentPage] = useState(1); // Current page number
    const previousFilteredData = useRef([]);
    // Update finalData based on changes in tableData, searchText, searchedColumn, filters, and customSort
    useEffect(() => {
        const filterExists = Object.values(filters).some(filter => filter?.length > 0);

        const sortedData = utils.ProcessSortingRules(customSort, tableData);
        let filteredData = searchText
            ? sortedData.filter(data =>
                data[searchedColumn]?.toString()?.toLowerCase()?.includes(searchText.toLowerCase())
              )
            : sortedData;

        if (filterExists) {
            Object.keys(filters).forEach(filterKey => {
                filteredData = filteredData.filter(data => filters[filterKey]?.includes(data[filterKey]));
            });
        }

         // Update finalData only if the filtered data has changed
         const filteredDataString = JSON.stringify(filteredData);
         if (filteredDataString !== JSON.stringify(previousFilteredData.current)) {
             previousFilteredData.current = filteredData;
             setFinalData(filteredData);
         }

    },[tableData, searchText, searchedColumn, filters, customSort])

    // Helper function to get paged data based on current page
    const pagedData = showPagination
        ? finalData.slice((currentPage - 1) * pageSize, currentPage * pageSize)
        : finalData;

    // Return pagedData and setCurrentPage for external pagination controls
    return { pagedData, finalData, currentPage, setCurrentPage };
}

export default usePagination;
